import React, { FC } from 'react';
import { upsertCountryBackNavLink } from '../../../Shared';
import FIRsOwn from '../../../FIR/FIRsOwn';
import { DetailsEditorHeaderSection, DetailsEditorWrapper } from '@wings-shared/layout';
import { useParams } from 'react-router';

interface Props {
  countryId?: number | string;
  title: string;
}

const AssociatedFIRs: FC<Props> = ({ title, countryId }) => {
  const params = useParams();
  const headerActions = () => {
    return (
      <DetailsEditorHeaderSection
        title={title}
        backNavLink={params && upsertCountryBackNavLink(Number(params.continentId))}
        backNavTitle={params?.countryId ? 'Countries' : 'Continents'}
        isEditMode={false}
        showBreadcrumb={Boolean(params?.continentId)}
      />
    );
  };

  return (
    <DetailsEditorWrapper
      headerActions={headerActions()}
      isEditMode={false}
      isBreadCrumb={Boolean(params?.continentId)}
    >
      <FIRsOwn showSearchHeader={false} countryId={Number(countryId)} />
    </DetailsEditorWrapper>
  );
};

export default AssociatedFIRs;
