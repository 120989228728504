import React, { Component, ReactNode } from 'react';
import { inject } from 'mobx-react';
import {
  AeronauticalInformationPublication,
  Region,
  State,
  CoreModule,
  City,
  Island,
  FIRsOwn,
  Metro,
  Continent,
  Settings,
} from './Modules';
import { IBaseModuleProps, Bulletins, UpsertBulletin, NO_SQL_COLLECTIONS, BulletinsReview } from '@wings/shared';
import { AuthStore, SettingsModuleSecurity } from '@wings-shared/security';
import { Routes, Route } from 'react-router-dom';
import {
  countrySidebarOptions,
  COUNTRY_MODULE_NAMES,
  updateCountrySidebarOptions,
  upsertTabBasePathFinder,
} from './Modules/Shared';
import { CountryModuleSecurity } from './Modules/Shared/Tools';
import { UpsertCountry } from './Modules/Core/Components';
import { NotFoundPage } from '@wings-shared/core';
import { ProtectedRoute } from '@wings-shared/layout';

@inject('sidebarStore')
export default class CountryApp extends Component<IBaseModuleProps> {
  private readonly redirectPath: string = '/countries';
  constructor(props) {
    super(props);
    this.props.sidebarStore?.setNavLinks(countrySidebarOptions(true), this.props.basePath);
    // Needs to set permissions after lazy loading
    SettingsModuleSecurity.updatePermissions();
    CountryModuleSecurity.init();
    AuthStore.configureAgGrid();
  }

  public render(): ReactNode {
    return (
      <Routes>
        <Route path="countries/*">
          <Route index element={<CoreModule key={'countries'} />} />
          <Route path="upsert/:viewMode/*" element={<UpsertCountry key={'country-add'} />} />
          <Route
            path="continents/:continentId/countries/upsert/:viewMode/*"
            element={<UpsertCountry key={'country-add-from-continent'} />}
          />
          <Route path="upsert/:countryId/:viewMode/*" element={<UpsertCountry key={'country-details-and-update'} />} />
          <Route path="continents/:continentId/upsert/:viewMode/*" element={<UpsertCountry key={'continent-view'} />} />
          <Route
            path="continents/:continentId/countries/upsert/:countryId/:viewMode/*"
            element={<UpsertCountry key={'continent-detail-and-update'} />}
          />
          <Route
            // changed path from ':continent/'
            path="continents/:continentId/countries"
            element={
              <ProtectedRoute
                redirectPath={this.redirectPath}
                hasPermission={CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.CONTINENTS)}
                element={<CoreModule key={'continent-countries'} />}
              />
            }
          />
          <Route
            path="firs"
            element={
              <ProtectedRoute
                element={<FIRsOwn />}
                hasPermission={CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.FIRS)}
                redirectPath={this.redirectPath}
              />
            }
          />
          <Route path="states" element={<State key={'states'} />} />
          <Route path=":countryId/states" element={<State key={'states-with-country'} />} />
          {/* added one more route for*/}
          <Route
            path="continents/:continentId/countries/:countryId/states"
            element={<State key={'states-with-continent-and-country'} />}
          />
          <Route path="cities" element={<City key={'cities'} />} />
          <Route path="states/:stateId/cities" element={<City key={'cities-with-states'} />} />
          <Route path=":countryId/states/:stateId/cities" element={<City key={'cities-with-country-and-states'} />} />
          <Route
            path="continents/:continentId/countries/:countryId/states/:stateId/cities"
            element={<City key={'cities-with-continent-and-country-and-states'} />}
          />
          <Route
            path="islands"
            element={
              <ProtectedRoute
                element={<Island key={'islands'} />}
                hasPermission={CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.ISLANDS)}
                redirectPath={this.redirectPath}
              />
            }
          />
          <Route
            path=":countryId/islands"
            element={
              <ProtectedRoute
                element={<Island key={'island-with-country'} />}
                hasPermission={CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.ISLANDS)}
                redirectPath={this.redirectPath}
              />
            }
          />
          {/* added more routes for*/}
          <Route
            path="states/:stateId/islands"
            element={
              <ProtectedRoute
                element={<Island key={'island-with-states'} />}
                hasPermission={CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.ISLANDS)}
                redirectPath={this.redirectPath}
              />
            }
          />
          <Route
            path=":countryId/states/:stateId/islands"
            element={
              <ProtectedRoute
                element={<Island key={'islands-with-country-and-state'} />}
                hasPermission={CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.ISLANDS)}
                redirectPath={this.redirectPath}
              />
            }
          />
          <Route
            path="continents/:continentId/countries/:countryId/islands"
            element={
              <ProtectedRoute
                element={<Island key={'islands-with-continent-and-country'} />}
                hasPermission={CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.ISLANDS)}
                redirectPath={this.redirectPath}
              />
            }
          />
          <Route
            path="continents/:continentId/countries/:countryId/states/:stateId/islands"
            element={
              <ProtectedRoute
                element={<Island key={'islands-with-continent-and-country-and-state'} />}
                hasPermission={CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.ISLANDS)}
                redirectPath={this.redirectPath}
              />
            }
          />

          <Route
            path="regions"
            element={
              <ProtectedRoute
                element={<Region />}
                hasPermission={CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.REGIONS)}
                redirectPath={this.redirectPath}
              />
            }
          />
          <Route
            path="continents"
            element={
              <ProtectedRoute
                element={<Continent />}
                hasPermission={CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.CONTINENTS)}
                redirectPath={this.redirectPath}
              />
            }
          />
          <Route
            path="metros"
            element={
              <ProtectedRoute
                element={<Metro />}
                hasPermission={CountryModuleSecurity.isSubModuleVisible(COUNTRY_MODULE_NAMES.METROS)}
                redirectPath={this.redirectPath}
              />
            }
          />
          <Route
            path="aeronautical-information"
            element={
              <ProtectedRoute
                element={<AeronauticalInformationPublication />}
                hasPermission={CountryModuleSecurity.isEditable}
                redirectPath={this.redirectPath}
              />
            }
          />
          <Route
            path="bulletins"
            element={
              <Bulletins
                defaultSidebarOptions={countrySidebarOptions}
                basePath="/countries"
                securityModule={CountryModuleSecurity}
                collectionName={NO_SQL_COLLECTIONS.COUNTRY_BULLETIN}
              />
            }
          />
          <Route
            path="bulletins/:viewMode"
            key="bulletin-detail"
            element={
              <UpsertBulletin
                isCountryBulletins={true}
                updatedSidebarOptions={() => updateCountrySidebarOptions('Bulletins')}
                defaultSidebarOptions={countrySidebarOptions}
                securityModule={CountryModuleSecurity}
                basePath="/countries"
                key={'bulletin-view'}
              />
            }
          />
          <Route
            path="bulletins/:bulletinId/:viewMode"
            key="bulletin-mode"
            element={
              <UpsertBulletin
                isCountryBulletins={true}
                updatedSidebarOptions={updateCountrySidebarOptions}
                defaultSidebarOptions={countrySidebarOptions}
                key={'bulletin-details'}
                securityModule={CountryModuleSecurity}
                basePath="/countries"
              />
            }
          />
          <Route
            path="purged-bulletins"
            element={
              <Bulletins
                purgedBulletins={true}
                defaultSidebarOptions={countrySidebarOptions}
                securityModule={CountryModuleSecurity}
                basePath="/countries"
                key={'purged-bulletins'}
                collectionName={NO_SQL_COLLECTIONS.COUNTRY_BULLETIN}
              />
            }
          />
          <Route
            path="purged-bulletins/:purgedBulletinId/:viewMode"
            element={
              <UpsertBulletin
                purgedBulletins={true}
                updatedSidebarOptions={() => updateCountrySidebarOptions('Purged Bulletins')}
                defaultSidebarOptions={countrySidebarOptions}
                securityModule={CountryModuleSecurity}
                basePath="/countries"
                key={'purged-bulletin-details'}
                isCountryBulletins={true}
              />
            }
          />
          <Route
            path="bulletins-review"
            element={
              <BulletinsReview
                isAirport={false}
                securityModule={CountryModuleSecurity}
                key={'country-bulletin-review'}
              />
            }
          />
          <Route path="settings" element={<Settings />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    );
  }
}
